
import merge from '~lodash.mergewith'
import { init, Integrations } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F06a148fce2f84f0a83024d7816700f09@o679650.ingest.sentry.io\u002F6222324",
    environment:"production",
    debug:false,
    integrations:[{"name":"BrowserTracing","options":{"idleTimeout":1000,"finalTimeout":30000,"heartbeatInterval":5000,"markBackgroundTransactions":true,"routingInstrumentation":function instrumentRoutingWithDefaults(
  customStartTransaction,
  startTransactionOnPageLoad = true,
  startTransactionOnLocationChange = true,
) {
  if (!types.WINDOW || !types.WINDOW.location) {
    (typeof __SENTRY_DEBUG__ === 'undefined' || __SENTRY_DEBUG__) && utils.logger.warn('Could not initialize routing instrumentation due to invalid location');
    return;
  }

  let startingUrl = types.WINDOW.location.href;

  let activeTransaction;
  if (startTransactionOnPageLoad) {
    activeTransaction = customStartTransaction({
      name: types.WINDOW.location.pathname,
      op: 'pageload',
      metadata: { source: 'url' },
    });
  }

  if (startTransactionOnLocationChange) {
    utils.addInstrumentationHandler('history', ({ to, from }) => {
      /**
       * This early return is there to account for some cases where a navigation transaction starts right after
       * long-running pageload. We make sure that if `from` is undefined and a valid `startingURL` exists, we don't
       * create an uneccessary navigation transaction.
       *
       * This was hard to duplicate, but this behavior stopped as soon as this fix was applied. This issue might also
       * only be caused in certain development environments where the usage of a hot module reloader is causing
       * errors.
       */
      if (from === undefined && startingUrl && startingUrl.indexOf(to) !== -1) {
        startingUrl = undefined;
        return;
      }

      if (from !== to) {
        startingUrl = undefined;
        if (activeTransaction) {
          (typeof __SENTRY_DEBUG__ === 'undefined' || __SENTRY_DEBUG__) && utils.logger.log(`[Tracing] Finishing current transaction with op: ${activeTransaction.op}`);
          // If there's an open transaction on the scope, we need to finish it before creating an new one.
          activeTransaction.finish();
        }
        activeTransaction = customStartTransaction({
          name: types.WINDOW.location.pathname,
          op: 'navigation',
          metadata: { source: 'url' },
        });
      }
    });
  }
},"startTransactionOnLocationChange":true,"startTransactionOnPageLoad":true,"enableLongTask":true,"_experiments":{},"traceFetch":true,"traceXHR":true,"tracingOrigins":["localhost",new RegExp("^\\\u002F", "")],"tracePropagationTargets":["localhost",new RegExp("^\\\u002F", "")]}}],
    release:"4f4667f8b9a5f170b3656539a9c153ac929b21c8",
    tracing:true,
    browserTracing:{},
    vueOptions:{"trackComponents":true},
    allowUrls:["stan.store","stanwith.me"],
    trackComponents:true,
    hooks:["activate","mount","update"],
    beforeSend:function(event, hint) {
        const getNestedObjectValue = (obj, path, def) => {
          const defaultValue = typeof def === 'string' ? def : '';
          const keys = path.split('.');
          const value = keys.reduce((accumulator, currentKey) => {
            return accumulator && accumulator[currentKey] !== undefined ? accumulator[currentKey] : undefined;
          }, obj);
          return typeof value === 'undefined' ? defaultValue : value;
        };

        let ignoreEvent = false;
        if (event.exception?.values.length > 0) {
          event.exception?.values.forEach((ex) => {
            const exValue = getNestedObjectValue(ex, 'ex.value.reason.message', ex.value);
            if (ex.type === 'Error') {
              const httpCode4xxIndex = ex.value.search(/\s4[0-9]{2,2}$/);
              const errorRequestFailedIndex = ex.value.toLowerCase().search('request failed with status code');
              if (ex.value?.indexOf('Network Error') > -1) {
                // eslint-disable-next-line no-console
                console.log('Ignoring Event', ex.type, ex.value);
                ignoreEvent = true;
              } else if (ex.value?.indexOf('Request aborted') > -1) {
                // eslint-disable-next-line no-console
                console.log('Ignoring Event', ex.type, ex.value);
                ignoreEvent = true;
              } else if (errorRequestFailedIndex > -1 && httpCode4xxIndex > -1) {
                ignoreEvent = true;
              } else if (ex.value?.indexOf('AbortError: The operation was aborted') > -1) {
                // eslint-disable-next-line no-console
                console.log('Ignoring Event', ex.type, ex.value);
                ignoreEvent = true;
              } else if (ex.value?.indexOf('NotSupportedError: The operation is not supported') > -1) {
                // eslint-disable-next-line no-console
                console.log('Ignoring Event', ex.type, ex.value);
                ignoreEvent = true;
              } else if (ex.value?.indexOf("Can't find variable: IFrameMessageHandler") > -1) {
                // eslint-disable-next-line no-console
                console.log('Ignoring Event', ex.type, ex.value.reason.message);
                ignoreEvent = true;
              }
            } else if (ex.type === 'UnhandledRejection' && exValue.indexOf('Non-Error promise rejection captured with value') > -1) {
              // https://github.com/getsentry/sentry-javascript/issues/2514
              // eslint-disable-next-line no-console
              console.log(`Ignoring Event: ${ex.type}, ${exValue}`);
              ignoreEvent = true;
            } else if (exValue.indexOf('type=unhandledrejection') > -1) {
              console.log(`Ignoring Event: ${ex.type}, ${exValue}`);
              ignoreEvent = true;
            } else if (ex.type === 'TypeError') {
              if (ex.value?.indexOf(`undefined is not an object (evaluating 'this.$route.matched')`) > -1) {
                console.log('Ignoring Event', ex.type, ex.value);
                ignoreEvent = true;
              } else if (
              ex.value?.indexOf(`Cannot read properties of undefined (reading 'domInteractive')`) > -1 ||
              ex.value?.indexOf(`undefined is not an object (evaluating 'this._perf.domInteractive')`) > -1)
              {
                // maybe nuxtjs-microsoft-clarity
                console.log('Ignoring Event', ex.type, ex.value);
                ignoreEvent = true;
              } else if (ex.value?.indexOf(`null is not an object (evaluating 'e.contentWindow.postMessage')`) > -1) {
                // maybe Stripe Captcha
                console.log('Ignoring Event', ex.type, ex.value);
                ignoreEvent = true;
              }
            } else if (ex.type === 'SecurityError') {
              if (ex.value?.indexOf(`Attempt to use history.replaceState() more than 100 times per 30 seconds`) > -1) {
                console.log('Ignoring Event', ex.type, ex.value);
                ignoreEvent = true;
              }
            } else if (ex.type === 'ReferenceError') {
              if (ex.value?.indexOf('find variable: clearClarity') > -1) {
                ignoreEvent = true;
                // eslint-disable-next-line no-console
                console.log('Ignoring Event', ex.type, ex.value);
              }
            }
          });
        }
        return ignoreEvent ? null : event;
      },
  }

  const { TryCatch } = Integrations

  config.integrations = [
    new ExtraErrorData(),
    new RewriteFrames(),
    new TryCatch({ eventTarget:false }),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
